import ReactTooltip from "react-tooltip";

function SyncStatus({ status, reason, id}) {
  switch (status) {
    case "PARTIAL":
      return (
        <>
          <span
            className="badge badge-pill badge-warning"
            data-tip="React-tooltip"
            data-for={id}
          >
            Partial
          </span>
          <ReactTooltip
            className="bg-warning"
            place="top"
            type="error"
            id={id}
            effect="solid"
          >
            <span>{"Payment/Receipt of goods not captured."}</span>
          </ReactTooltip>
        </>
      );
    case "SUCCESSFUL":
      return <span className="badge badge-pill badge-success">Success</span>;
    case "FAILED":
      return (
        <div>
          <span
            data-tip="React-tooltip"
            data-for={id}
            className="badge badge-pill badge-danger"
          >
            Failed
          </span>
          <ReactTooltip
            className="bg-danger"
            place="top"
            type="error"
            id={id}
            effect="solid"
          >
            <span>{reason ? reason : "An error occurred."}</span>
          </ReactTooltip>
        </div>
      );
    default:
      return <span className="badge badge-pill badge-info">Pending</span>;
  }
}

export default SyncStatus;