import React, { useEffect, useState } from "react";
import SearchLogo from "src/assets/Search";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";

function Table({ data, columns, migrate, cart, refresh }) {
  const [value, setValue] = useState();
  const job = useSelector((state) => state[cart].job.data);
  const jobRunning =
    job && (job.jobStatus === "QUEUED" || job.jobStatus === "IN_PROGRESS") ? true : false;
  const [triggered, setTriggered] = useState(false);
  const dispatch = useDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const colummsLength = columns.length;
  const dataLength = data.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  async function handleSyncSubmit() {
    setTriggered(true);
    try {
      const resp = await migrate();
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job started",
        })
      );
    } catch (err) {
      if (cart !== "amazon") {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data.message,
          })
        );
      }
    }
    setTriggered(false);
  }

  return (
    <>
      <div className="d-flex flex-row mb-3 justify-content-between" style={{align: 'right'}}>
        <form className="form-inline">
          <div className="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text bg-light">
                <span className="svg-icon svg-disabled">
                  <SearchLogo />
                </span>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={handleChange}
              value={value}
            />
          </div>
        </form>
      </div>
      <div className="card dk-card" style={{ overflowX: "auto" }}>
        <table className="table m-0 dk-table-hover dk-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    <span className="dk-table-header-content mr-2">{column.render("Header")}</span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps([{ className: cell.column.className }])}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter((option) => option.value === pageSize)}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ArrowRight />
            </span>
          </button>
          <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Table;
