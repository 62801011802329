function UOMIcon() {
  return (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="225.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M1635 2221 c-16 -10 -94 -84 -173 -163 l-142 -143 95 -95 c52 -52 95
-99 95 -105 0 -5 -21 -30 -45 -54 l-46 -45 -99 99 -100 100 -97 -98 -98 -97
100 -100 99 -100 -49 -44 c-28 -25 -52 -45 -55 -46 -3 0 -48 43 -100 95 l-95
95 -97 -97 -98 -98 97 -97 97 -98 -49 -50 -49 -50 -98 97 -98 98 -305 -305
c-331 -331 -340 -342 -317 -417 9 -29 63 -90 240 -265 l228 -228 51 0 c41 0
58 6 85 27 18 15 390 382 825 817 573 571 796 800 804 824 25 75 15 89 -228
327 -124 121 -232 223 -242 227 -33 14 -106 8 -136 -11z m-1028 -1571 c65 -39
85 -105 54 -174 -15 -36 -30 -50 -64 -67 -60 -29 -108 -20 -159 31 -34 33 -38
43 -38 86 0 87 55 143 140 144 19 0 49 -9 67 -20z"/>
</g>
</svg>
  );
}

export default UOMIcon;
