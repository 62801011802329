import {
    getFoodicsAccounts,
    getFoodicsConfig,
    getFoodicsPurchases,
    getFoodicsInventory,
    getFoodicsOrders,
    getFoodicsJobStatus,
    getFoodicsProdConfig,
    getFoodicsPaymentAccountConfig,
    getFoodicsUOMConfig,
    getFoodicsPurchaseOrders,
  } from "src/api";
  import {
    FOODICS_FAILED_CONFIG_LOAD,
    FOODICS_SET_CONFIG,
    FOODICS_FAILED_ACCOUNT_LOAD,
    FOODICS_SET_ACCOUNTS,
    FOODICS_FAILED_PURCHASES_LOAD,
    FOODICS_SET_PURCHASES,
    FOODICS_FAILED_INVENTORY_LOAD,
    FOODICS_SET_INVENTORY,
    FOODICS_SET_UOM_CONFIG,
    FOODICS_SET_PURCHASE_ORDERS,
    FOODICS_FAILED_PURCHASE_ORDERS_LOAD,
    FOODICS_FAILED_UOM_CONFIG_LOAD,
    FOODICS_FAILED_ORDERS_LOAD,
    FOODICS_SET_ORDERS,
    FOODICS_SET_JOB,
    FOODICS_FAILED_JOB_LOAD,
    FOODICS_SET_PRODUCT_CONFIG,
    FOODICS_FAILED_PRODUCT_CONFIG_LOAD,
    FOODICS_SET_PAYMENT_CONFIG,
    FOODICS_FAILED_PAYMENT_CONFIG_LOAD,
  } from "../types";
  
  export function loadConfig() {
    return async function (dispatch, getState) {
      try {
        const resp = await getFoodicsConfig();
        if (resp) {
          dispatch(setConfig(resp));
        } else {
          dispatch(setConfig({}))
        }
      } catch (err) {
        dispatch(failedConfigLoad());
      }
    };
  }
  
  export function setConfig(config) {
    return {
      type: FOODICS_SET_CONFIG,
      config: config,
    };
  }
  
  export function failedConfigLoad() {
    return {
      type: FOODICS_FAILED_CONFIG_LOAD,
    };
  }
  
  export function loadAccounts() {
    return async function (dispatch, getState) {
      try {
        const accounts = await getFoodicsAccounts();
        dispatch(setAccounts(accounts));
      } catch (err) {
        dispatch(failedAccountLoad());
      }
    };
  }
  
  export function setAccounts(accounts) {
    return {
      type: FOODICS_SET_ACCOUNTS,
      accounts: accounts,
    };
  }
  
  export function failedAccountLoad() {
    return {
      type: FOODICS_FAILED_ACCOUNT_LOAD,
    };
  }
  
  export function loadPurchases() {
    return async function (dispatch, getState) {
      try {
        const purchases = await getFoodicsPurchases();
        dispatch(setPurchases(purchases));
      } catch (err) {
        dispatch(failedPurchasesLoad());
      }
    };
  }
  
  export function setPurchases(purchases) {
    return {
      type: FOODICS_SET_PURCHASES,
      purchases: purchases,
    };
  }
  
  export function failedPurchasesLoad() {
    return {
      type: FOODICS_FAILED_PURCHASES_LOAD,
    };
  }
  



  export function loadPurchaseOrders() {
    return async function (dispatch, getState) {
      try {
        const purchases = await getFoodicsPurchaseOrders();
        dispatch(setPurchaseOrders(purchases));
      } catch (err) {
        dispatch(failedPurchaseOrders());
      }
    };
  }
  
  export function setPurchaseOrders(purchaseOrders) {
    return {
      type: FOODICS_SET_PURCHASE_ORDERS,
      purchaseOrders: purchaseOrders,
    };
  }
  
  export function failedPurchaseOrders() {
    return {
      type: FOODICS_FAILED_PURCHASE_ORDERS_LOAD,
    };
  }




  export function loadOrders() {
    return async function (dispatch, getState) {
      const ordersLoading = getState().foodics.orders.loading;
  
      try {
        const orders = await getFoodicsOrders();
        dispatch(setOrders(orders));
      } catch (err) {
        dispatch(failedOrdersLoad());
      }
    };
  }
  
  export function setOrders(orders) {
    return {
      type: FOODICS_SET_ORDERS,
      orders: orders,
    };
  }
  
  export function failedOrdersLoad() {
    return {
      type: FOODICS_FAILED_ORDERS_LOAD,
    };
  }
  
  export function loadInventory() {
    return async function (dispatch, getState) {
      try {
        const inventory = await getFoodicsInventory();
        dispatch(setInventory(inventory));
      } catch (err) {
        dispatch(failedInventoryLoad());
      }
    };
  }
  
  export function setInventory(inventory) {
    return {
      type: FOODICS_SET_INVENTORY,
      inventory: inventory,
    };
  }
  
  export function failedInventoryLoad() {
    return {
      type: FOODICS_FAILED_INVENTORY_LOAD,
    };
  }
  
  export function setJob(job) {
    return {
      type: FOODICS_SET_JOB,
      job: job,
    };
  }
  
  export function loadJob() {
    return async function(dispatch) {
      try {
        const job = await getFoodicsJobStatus();
        dispatch(setJob(job));
      } catch (err) {
        dispatch(failedJobLoad());
      }
    }
  }
  
  export function failedJobLoad() {
    return {
      type: FOODICS_FAILED_JOB_LOAD,
    };
  }

  export function setProductConfig(productConfig) {
    return {
      type: FOODICS_SET_PRODUCT_CONFIG,
      productConfig: productConfig,
    };
  }
  
  export function loadProductConfig() {
    return async function(dispatch) {
      try {
        const resp = await getFoodicsProdConfig();
        if (resp) {
          dispatch(setProductConfig(resp));
        } else {
          dispatch(setProductConfig({}))
        }
      } catch (err) {
        dispatch(failedProductConfigLoad());
      }
    }
  }
  
  export function failedProductConfigLoad() {
    return {
      type: FOODICS_FAILED_PRODUCT_CONFIG_LOAD,
    };
  }


  export function setUOMConfig(uomConfig) {
    return {
      type: FOODICS_SET_UOM_CONFIG,
      uomConfig: uomConfig,
    };
  }
  
  export function loadUOMConfig() {
    return async function(dispatch) {
      try {
        const resp = await getFoodicsUOMConfig();
        if (resp) {
          dispatch(setUOMConfig(resp));
        } else {
          dispatch(setUOMConfig({}))
        }
      } catch (err) {
        dispatch(failedUOMConfigLoad());
      }
    }
  }
  
  export function failedUOMConfigLoad() {
    return {
      type: FOODICS_FAILED_UOM_CONFIG_LOAD,
    };
  }
  
 
  export function setPaymentAccountConfig(paymentConfig) {
    return {
      type: FOODICS_SET_PAYMENT_CONFIG,
      paymentConfig: paymentConfig
    };
  }
  
  export function loadPaymentAccountConfig() {
    return async function(dispatch) {
      try {
        const resp = await getFoodicsPaymentAccountConfig();
        if (resp) {
          dispatch(setPaymentAccountConfig(resp));
        } else {
          dispatch(setPaymentAccountConfig({}))
        }
      } catch (err) {
        dispatch(failedPaymentAccountConfigLoad());
      }
    }
  }
  
  export function failedPaymentAccountConfigLoad() {
    return {
      type: FOODICS_FAILED_PAYMENT_CONFIG_LOAD,
    };
  }