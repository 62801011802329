export default function customSelectTheme(defaultTheme) {
  return {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: "#800020",
      primary25: "#f6f6f6",
    },
  };
}
