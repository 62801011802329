import { combineReducers } from "redux";
import toasts from "src/redux/reducers/toasts";
import auth from "src/redux/reducers/auth";
import foodics from "src/redux/reducers/foodics";

export default combineReducers({
  toasts,
  auth,
  foodics,
});
