import { CREATE_TOAST, DELETE_TOAST, MARK_TOAST_DELETED } from "src/redux/types";

function toastsReducer(state = [], action = {}) {
  switch (action.type) {
    case CREATE_TOAST:
      return [
        ...state,
        {
          id: action.id,
          type: action.toast.type,
          title: action.toast.title,
          message: action.toast.message,
          active: true,
        },
      ];
    case DELETE_TOAST:
      const index = state.findIndex((message) => message.id === action.id);
      if (index >= 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return state;
      }
    case MARK_TOAST_DELETED:
      const deletionIndex = state.findIndex((message) => message.id === action.id);
      if (deletionIndex >= 0) {
        return [
          ...state.slice(0, deletionIndex),
          { ...state[deletionIndex], active: false },
          ...state.slice(deletionIndex + 1),
        ];
      } else {
        return state;
      }
    default:
      return state;
  }
}

export default toastsReducer;
