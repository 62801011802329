import { Redirect, Route, Switch } from "react-router-dom";
import "./index.css";
import "src/styles/index.scss";
import Dashboard from "./pages/Dashboard";
import LoginCheck from "./components/LoginCheck";
import OAuth2 from "./pages/OAuth2";
import NotFound from "./pages/404";
import FoodicsOAuth from "./components/FoodicsOAuth";

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/app" />
      </Route>
      <Route path="/app">
        <LoginCheck>
          <Dashboard />
        </LoginCheck>
      </Route>
      <Route path="/oauth">
        <OAuth2 />
      </Route>
      <Route path="/404">
        <NotFound />
      </Route>
      <Route path="/cart-integration">
        <FoodicsOAuth />
      </Route>
    </Switch>
  );
}

export default App;
