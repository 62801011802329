import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadInventory, loadConfig, loadJob } from "src/redux/actions/foodics";
import Alert from "src/components/Alert";
import Table from "src/components/Table";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateFoodicsData } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import FoodicsTable from "src/components/FoodicsTable";


function Inventory() {
  const [syncStatus, setSyncStatus] = useState("enabled");
  const inventory = useSelector((state) => state.foodics.inventory);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.foodics.job);

  useEffect(() => {
    dispatch(loadInventory());
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => inventory.data, [inventory]);

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
          {row.original.productName} {row.original.productVariantName && row.original.productVariantName !== "Default Title" && "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "Unit Price",
        accessor: "productUnitPrice",
        Cell: ({ cell: { row } }) => (
          <span>
            SAR {" "}
            {row.original.productUnitPrice}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Deskera Product Code",
        accessor: "deskeraId",
      },
    ],
    []
  );
  
  const config = useSelector((state) => state.foodics.config);

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateFoodicsData();
    } catch (err) {
      dispatch(addToast({
        type: "danger",
        title: "Failure",
        message: err.message
      }))
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Inventory Items</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/Foodics" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <FoodicsTable data={data} columns={columns} migrate={migrateFoodicsData} cart='foodics' pages = "inventory"/>
    </div>
  );
}

export default Inventory;
