import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { setFoodicsConnection } from "src/api";
import Alert from "src/components/Alert";

function FoodicsOAuth() {
  const [status, setStatus] = useState("connecting");
  const history = useHistory();

  useEffect(() => {
    let windowUrl = new URL(window.location);
    let oauthParams = windowUrl.searchParams;

    const connectionInfo = {
      code: oauthParams.get("code"),
      oauth_state: oauthParams.get("state"),
    };

    async function updateConnection() {
      try {
        await setFoodicsConnection(connectionInfo);
        setStatus("connected");
      } catch {
        setStatus("failed");
      } finally {
        setTimeout(() => history.push("/app"), 1000);
      }
    }

    updateConnection();
  }, []);

  return status === "connecting" ? (
    <>
      <p>Connecting your Foodics account.</p>
      <div class="spinner-border text-muted" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </>
  ) : status === "connected" ? (
    <Alert type="success">
      Foodics account successfully connected. Redirecting you to Deskera ECom.
    </Alert>
  ) : (
    <Alert type="danger">
      Failed to connect your Foodics account. Please try authorizing again.
    </Alert>
  );
}

export default FoodicsOAuth;
