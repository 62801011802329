import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import FoodicsLogo from "src/assets/FoodicsLogo";
import { getLogout } from "src/api";
import { loadAccounts, setAccounts } from "src/redux/actions/foodics";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Redirect } from "react-router-dom";

function getData() {
  return {
    logo: <FoodicsLogo />,
  };
}

function Header() {
  const data = getData();
  const currentUser = useSelector((state) => state.auth.user);
  const node = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const onClick = () => {
    setIsOpen(true);
  };

  async function handleClose(){
    await getLogout();

    setIsOpen(false);
    window.location.href = "https://auth-dev.deskera.xyz/auth/signin?redirectUrl=https:%2F%2Ffoodics-dev.deskera.xyz";
  };

  return (
    <nav
      className="navbar navbar-light bg-white py-0 dk-shadow-sm justify-content-between"
      style={{
        height: 60,
        zIndex: 100,
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <span className="navbar-brand">
        <Link
          to="/app"
          className="text-decoration-none text-dark d-flex align-items-center"
        >
          <h2 className="svg-icon svg-baseline">{data.logo}</h2>
          <span
            className="ml-2 dk-shadow"
            style={{ fontWeight: 600, fontSize: 20 }}
          >
            Foodics Connect
          </span>
        </Link>
      </span>

      {currentUser && (
        <>
          <span ref={node} onClick={onClick}>
            <Avatar name={currentUser.name} />
          </span>
          <Menu
            style ={{marginTop: '-20%', marginLeft: '90%'}}
              keepMounted
              anchorEl={isOpen}
              onClose={handleClose}
              open={Boolean(isOpen)}
            >
              {/* <MenuItem> <b>{currentUser.name}</b> </MenuItem> */}
              <MenuItem onClick={handleClose}> Logout </MenuItem>
            </Menu>
        </>
      )}
    </nav>
  );
}

export default Header;
