import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import CloseIcon from "src/assets/Close";
import { deleteToast, markToastDeleted } from "src/redux/actions/toasts";
import { CSSTransition } from "react-transition-group";

const styleMap = {
  success: ["border-success", "bg-success"],
  danger: ["border-danger", "bg-danger"],
};

function ToastList() {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts);

  const messages = toasts.map((toast) => (
    <CSSTransition
      in={toast.active}
      appear={true}
      classNames="scale-opacity"
      timeout={300}
      unmountOnExit
      onExited={() => dispatch(deleteToast(toast.id))}
    >
      <div
        class={clsx(
          "alert",
          "dk-shadow-sm",
          "d-flex",
          "flex-row",
          "justify-content-between",
          "border-bottom",
          "text-light",
          ...styleMap[toast.type]
        )}
        style={{ border: "none" }}
      >
        <p className="m-0">
          <p className="mb-1 font-weight-bold">{toast.title}</p>
          <p className="m-0">{toast.message}</p>
        </p>
        <button className="btn p-0" onClick={(e) => dispatch(markToastDeleted(toast.id))}>
          <span className="svg-icon svg-white svg-baseline">
            <CloseIcon />
          </span>
        </button>
      </div>
    </CSSTransition>
  ));
  return <div class="toast-list" style={{ zIndex: 100}}>{messages}</div>;
}

export default ToastList;
