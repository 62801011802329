import { useEffect, useState } from "react";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import { setFoodicsUOMConfig, getFoodicsUOM } from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import {
  loadConfig,
  setConfig,
  loadAccounts,
  loadUOMConfig,
  setUOMConfig,
} from "src/redux/actions/foodics";
import { addToast } from "src/redux/actions/toasts";

function UOM() {
  const accounts = useSelector((state) => state.foodics.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [uomOptions, setUOMOptions] = useState([]);
  const uomConfig = useSelector((state) => state.foodics.uomConfig);
  const [formErrors, setFormErrors] = useState({});
  const [UOMMappingsConfigured, setUOMMappingsConfigured] = useState(false);
  const [saveUOMConfigLoading, setSaveUOMConfigLoading] = useState(false);
  const [configUOMMappings, setConfigUOMMappings] = useState([]);

  function initialiseConfigUOMMappings() {
    const configUOMMapping = [];
    for (let i = 0; i < uomConfig.data.length; i++) {
      let uom = uomConfig.data[i];
      let configUOM = {
        name: uom.uomName,
        id: uom.id,
        deskeraId: uom.deskeraId,
        options: uomOptions,
      };
      configUOMMapping.push(configUOM);
    }
    setConfigUOMMappings(configUOMMapping);
    console.log(configUOMMapping);
  }

  useEffect(() => {
    handleConfigLoad1();
  }, [uomConfig, uomOptions]);

  useEffect(() => {
    initialiseConfigUOMMappings();
  }, [uomConfig, uomOptions]);

  function handleConfigLoad1() {
    setUOMMappingsConfigured(true);
    configUOMMappings.forEach((configUOMMapping) => {
      if (!uomConfig.data[configUOMMapping.deskeraId]) {
        setUOMMappingsConfigured(false);
        return;
      }
    });
  }

  async function getFoodicsUOMDropdown() {
    try {
      const uom = await getFoodicsUOM();
      const uomOptionss = [];
      uom.forEach((parameter) => {
        uomOptionss.push({ value: parameter.id, label: parameter.name });
      });
      setUOMOptions(uomOptionss);
      console.log(uomOptionss);
    } catch {
      setError("Failed to load UOM Options");
    }
  }

  function setUOM(e, i) {
    const uom = uomConfig.data;
    const conf = uom.splice(i, 1)[0];
    conf.deskeraId = e.value;
    uom.splice(i, 0, conf);
    console.log(uom);

    dispatch(setUOMConfig(uom));
  }

  useEffect(() => {
    getFoodicsUOMDropdown();
    dispatch(loadAccounts());
    dispatch(loadConfig());
    dispatch(loadUOMConfig());
  }, []);

  async function saveUOMConfig(e) {
    e.preventDefault();
    setSaveUOMConfigLoading(true);
    try {
      const configResp = await setFoodicsUOMConfig(uomConfig.data);
      dispatch(setUOMConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "UOM config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveUOMConfigLoading(false);
    dispatch(loadUOMConfig());
  }

  return (
    <div className="p-4 w-auto">
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>UOM Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!UOMMappingsConfigured && !uomConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be
                  synced with Deskera Books.
                </Alert>
              )}
              {uomConfig.data.length === 0 && (
                <span className="mt-2 text-muted">
                  No payment method accounts found.
                </span>
              )}
              {uomConfig.data.length > 0 &&
                configUOMMappings.map((configUOMMapping, i) => (
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>{configUOMMapping.name}</b>
                    </div>
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configUOMMapping.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) => {
                        setUOM(e, i);
                      }}
                      value={configUOMMapping.options.filter(
                        (obj) => obj.value == configUOMMapping.deskeraId
                      )}
                    />
                    {formErrors[configUOMMapping.deskeraId] && (
                      <div>{formErrors[configUOMMapping.deskeraId]}</div>
                    )}
                  </div>
                ))}
              {uomConfig.data.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveUOMConfig}
                    disabled={saveUOMConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveUOMConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UOM;
