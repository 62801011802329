import React, { useEffect, useState } from "react";
import SearchLogo from "src/assets/Search";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useFilters,
} from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";
import makeAnimated from "react-select/animated";
import {
  loadInventory,
  loadOrders,
  loadPurchaseOrders,
} from "src/redux/actions/foodics";
import {
  getDeskeraBooksWarehouses,
  getFoodicsOrdersBranches,
  getFoodicsPurchaseOrdersWarehouses,
  setFoodicsInventory,
} from "src/api";
import CloseIcon from "src/assets/Close";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function FoodicsTable({ data, columns, migrate, cart, pages }) {
  const [value, setValue] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [options, setOptions] = useState();
  const [branches, setBranches] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [selectedBranches, setSelectedBranches] = useState([]);
  const [refreshButton, setRefreshButton] = useState(false);
  const refresh = () => {
    if (!refreshButton) {
      setRefreshButton(true);

      if (pages === "orders") dispatch(loadOrders());

      if (pages === "inventory") dispatch(loadInventory());

      if (pages === "purchases" || pages === "contacts")
        dispatch(loadPurchaseOrders());

      setTimeout(() => {
        setRefreshButton(false);
      }, 30000);
    }
  };
  const [syncConfigOpen, setSyncConfigOpen] = useState(false);
  const job = useSelector((state) => state[cart].job.data);
  const jobRunning =
    job && (job.jobStatus === "QUEUED" || job.jobStatus === "IN_PROGRESS")
      ? true
      : false;
  const [triggered, setTriggered] = useState(false);
  const dispatch = useDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
    useFilters,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function updateOptions() {
    const products = data;
    const productsOptions = [];
    products.forEach((product) => {
      productsOptions.push({ value: product[2], label: product[2] });
    });
    setOptions(productsOptions);
  }

  async function getBranches() {
    if (pages === "orders") {
      try {
        const branchops = await getFoodicsOrdersBranches();
        const branchOptions = [];
        branchops.forEach((branch) => {
          branchOptions.push({ value: branch.id, label: branch.name });
        });
        setBranches(branchOptions);
      } catch (err) {
        console.log(err);
      }
    }
    if (pages === "purchases") {
      try {
        const warehouses = await getFoodicsPurchaseOrdersWarehouses();
        const warehouseOptions = [];
        warehouses.forEach((warehouse) => {
          warehouseOptions.push({
            value: warehouse.warehouseId,
            label: warehouse.warehouseName,
          });
        });
        setBranches(warehouseOptions);
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    updateOptions();
    getBranches();
  }, []);

  function foodicsSyncConfig() {
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            className="card dk-card dk-card-shadow d-flex"
            style={{ minWidth: "30%" }}
          >
            <div className="card-body d-flex flex-column">
              <div className="card-body d-flex flex-column">
                <div className="text-muted mb-2">Sync for date:</div>
                {/* <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  className="input-group"
                /> */}
                <div class="overflow-hidden">
                  <DatePicker
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </div>
                {pages === "purchases" && (
                  <div className="text-muted mb-2 mt-3">
                    Sync for Warehouse:
                  </div>
                )}
                {pages !== "purchases" && (
                  <div className="text-muted mb-2 mt-3">Sync for branch:</div>
                )}
                <MultiSelect
                  options={branches} // Options to display in the dropdown
                  value={selectedBranches} // Preselected value to persist in dropdown
                  onChange={setSelectedBranches} // Function will trigger on select event
                  valueRenderer={customValueRenderer}
                />
              </div>
              <div className="d-flex flex-row-reverse mt-3">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={(e) => {
                    setSyncConfigOpen(false);
                    handleSyncSubmit();
                  }}
                  // disabled={saveShopLoading}
                >
                  {/* <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveShopLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span> */}
                  <span>Sync</span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                  onClick={(e) => {
                    setSyncConfigOpen(false);
                  }}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-disabled">
                    <CloseIcon />
                  </span>
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const [progress, setProgress] = useState(0);
  const [slowProgress, setSlowProgress] = useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSlowProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const customOptionRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`item-renderer ${disabled && "disabled"}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span>{option.label}</span>
    </div>
  );

  const customValueRenderer = (selected, _options) => {
    if (pages === "purchases") {
      return selected.length ? (
        <div className="d-flex flex-row overflow-auto">
          {selected.map(({ label }) => {
            return (
              <div
                className="rounded-pill text-bold mr-1 bg-white p-1 border"
                style={{ "border-color": "#d0cfca" }}
              >
                {label}{" "}
                <span
                  className="svg-icon svg-baseline mr-2 svg-gray"
                  onClick={() => {
                    let newSelectedBranches = [];
                    selectedBranches.forEach((br) => {
                      if (br.label !== label) {
                        newSelectedBranches.push(br);
                      }
                    });
                    setSelectedBranches(newSelectedBranches);
                    customValueRenderer(selectedBranches, _options);
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        "Select Warehouses"
      );
    }

    return selected.length ? (
      <div className="d-flex flex-row overflow-auto">
        {selected.map(({ label }) => {
          return (
            <div
              className="rounded-pill text-bold mr-1 bg-white p-1 border"
              style={{ "border-color": "#d0cfca" }}
            >
              {label}{" "}
              <span
                className="svg-icon svg-baseline mr-2 svg-gray"
                onClick={() => {
                  let newSelectedBranches = [];
                  selectedBranches.forEach((br) => {
                    if (br.label !== label) {
                      newSelectedBranches.push(br);
                    }
                  });
                  setSelectedBranches(newSelectedBranches);
                  customValueRenderer(selectedBranches, _options);
                }}
              >
                <CloseIcon />
              </span>
            </div>
          );
        })}
      </div>
    ) : (
      "Select Branch"
    );
  };

  const colummsLength = columns.length;
  const dataLength = data.length;

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  async function handleSyncSubmit() {
    if (!triggered) {
      setTriggered(true);
      var branchIds = selectedBranches.map((br) => br.value);
      var sd = startDate;
      var startMonth = sd.getMonth() + 1;
      var ed = endDate ? endDate : startDate;
      var endMonth = ed.getMonth() + 1;
      const requestBody = {
        branchIds: branchIds,
        endDate: ed.getDate() + "-" + endMonth + "-" + ed.getUTCFullYear(),
        startDate: sd.getDate() + "-" + startMonth + "-" + sd.getUTCFullYear(),
      };
      try {
        const resp = await migrate(requestBody);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync job completed",
          })
        );
      } catch (err) {
        if (cart !== "foodics") {
          dispatch(
            addToast({
              type: "danger",
              title: "Failure",
              message: err.response.data.message,
            })
          );
        }
      }
      setTriggered(false);
    }
  }
  function loadingProgress() {
    let value = progress;
    if(refreshButton){
      value = slowProgress;
    }
    return (
      <div style={{ paddingBottom: 10 }}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
      </div>
    );
  }

  async function loadFoodicsInventory() {
    setTriggered(true);
    try {
      const purchases = await setFoodicsInventory();
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );
      setTriggered(false)
    } catch (err) {
      setTriggered(false)
    }    
}


  return (
    <>
      {syncConfigOpen && foodicsSyncConfig()}
      <div className="d-flex flex-row mb-3 justify-content-between">
        <div>
          <button
            className="btn btn-outline-success mr-2"
            onClick={refresh}
            disabled={refreshButton}
          >
            <span className="svg-icon">
              <ArrowClockwise />
            </span>
          </button>
          {pages !== "inventory" && pages !== "contacts" && (
            <button
              className="btn text-success px-4 dk-btn font-weight-bold border border-success mr-2"
              onClick={(e) => setSyncConfigOpen(true)}
              disabled={triggered}
            >
              {/* <span className="svg-icon svg-baseline mr-2 svg-success">
              {triggered ? (
                <div class="spinner-border text-success spinner-border-sm" role="status"></div>
              ) : (
                <></>
              )}
            </span> */}
              <span>Sync Data</span>
            </button>
          )}
          {pages === "inventory" && (
            <button
              className="btn dk-btn font-weight-bold text-success border border-success px-4"
              style={{ paddingLeft: 0 }}
              onClick={(e) => loadFoodicsInventory()}
              disabled={triggered}
            >
              <span>Sync Data</span>
            </button>
          )}
          {/* <button
            className="btn text-success px-4 dk-btn font-weight-bold border border-success"
            onClick={(e) => setSyncConfigOpen(true)}
            disabled={triggered}
          >
            <span className="svg-icon svg-baseline mr-2 svg-success">
              {triggered ? (
                <div class="spinner-border text-success spinner-border-sm" role="status"></div>
              ) : (
                <></>
              )}
            </span>
            <span>Add Filters</span>
          </button> */}
        </div>

        <form className="form-inline">
          <div className="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text bg-light">
                <span className="svg-icon svg-disabled">
                  <SearchLogo />
                </span>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={handleChange}
              value={value}
            />
          </div>
        </form>
      </div>
      {(triggered || refreshButton) && loadingProgress()}
      <div className="card dk-card" style={{ overflowX: "auto" }}>
        <table
          className="table m-0 dk-table-hover dk-table"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                  >
                    <span className="dk-table-header-content mr-2">
                      {column.render("Header")}{" "}
                    </span>
                    {/* <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>  */}
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span
                      className="mt-3 svg-disabled"
                      style={{ width: "50px", height: "50px" }}
                    >
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">
                      No records were found.
                    </span>
                  </div>
                </td>
              </tr>
            )}
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter(
              (option) => option.value === pageSize
            )}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button
            className="btn mr-2"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button
            className="btn mr-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button
            className="btn mr-2"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <button
            className="btn"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span
              className={clsx(
                "svg-icon svg-baseline",
                canNextPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default FoodicsTable;
