import clsx from "clsx";

function Alert({ type, className, children }) {
  const styles = {
    warning: ["alert-warning", "border-warning"],
    danger: ["alert-danger", "border-danger"],
    success: ["alert-success", "border-success"],
    primary: ["alert-primary", "border-primary"],
  };

  return (
    <div className={clsx("alert dk-alert border-bottom", ...styles[type], className)} role="alert">
      {children}
    </div>
  );
}

export default Alert;
